import MobileSelect, { CascadeData } from 'mobile-select';
import { useRef, useState } from 'react';
import regions from './area.json';
interface Props {
  onChange: (v: string[]) => void;
  someProvince: string[];
  // 禁发省份区
  excludeRegions?: excludedOptions;
  // 提单省份
  subProvince?: string[];
}
interface Options {
  value: string;
  label: string;
  disabled?: boolean;
  children: any;
}
interface excludedOptions {
  provinces: string[];
  cities: string[];
  district: string[];
}
export default function MCityPicker({ onChange, someProvince, excludeRegions, subProvince }: Props) {
  const pickerRef = useRef<MobileSelect | null>(null);
  const [select, setSelect] = useState(false);

  // 遍历去除禁选地区
  function filterRegions(regions: Options[], excludedRegions: excludedOptions) {
    return regions
      .filter((provinceObj) => !excludedRegions.provinces.includes(provinceObj.label))
      .map((provinceObj) => ({
        label: provinceObj.label,
        children: provinceObj.children
          .filter((cityObj: Options) => !excludedRegions.cities.includes(cityObj.label))
          .map((cityObj: Options) => ({
            label: cityObj.label,
            children: cityObj.children.filter((dist: Options) => !excludedRegions.district.includes(dist.label)),
          })),
      }));
  }

  function handleRef(v: any) {
    if (pickerRef.current) return;
    let arrList: Options[] = [],
      obj: excludedOptions = {
        provinces: !!excludeRegions?.provinces ? excludeRegions.provinces : [],
        cities: !!excludeRegions?.cities ? excludeRegions.cities : [],
        district: !!excludeRegions?.district ? excludeRegions.district : [],
      };
    if (subProvince && subProvince.length) {
      regions.forEach((item) => {
        subProvince.forEach((i) => {
          if (item.label === i) {
            arrList.push(item);
          }
        });
      });
    } else {
      arrList = regions;
    }
    const filefilterRegions = filterRegions(arrList, obj);
    let data = filefilterRegions && filefilterRegions.length > 0 ? filefilterRegions : [{ label: '暂无可选城市' }];
    if (someProvince.length > 0) {
      let _data = [];
      for (let region of data) {
        for (let some of someProvince) {
          if (some.includes(region.label) || region.label.includes(some)) {
            _data.push(region);
          }
        }
      }
      data = _data;
    }

    pickerRef.current = new MobileSelect({
      trigger: '#m-city-picker',
      title: '地址选择',
      wheels: [{ data }],
      keyMap: { id: 'value', value: 'label', childs: 'children' },
      onChange: (cur: any) => {
        if (!select) {
          setSelect(true);
        }
        onChange((cur as CascadeData[]).map((i) => (i as CascadeData).label));
      },
    });
  }
  return (
    <div id="m-city-picker" className={`value ${select ? '' : 'place-holder'}`} ref={(v) => handleRef(v)}>
      请选择地区
    </div>
  );
}
